/* eslint-disable import/exports-last */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unused-modules */
import getHexColorFromNamed from '@/helpers/utils/getHexColorFromNamed'
import sanitize from 'sanitize-html'
import { unescape } from 'lodash'
import { z } from 'zod'

function expandShortHandHexColor(color: string) {
  if (color.length !== 3) return color
  return color
    .split('')
    .map((char) => char.repeat(2))
    .join('')
}

function htmlColorTransform(color: unknown) {
  let parsedColor = color
  if (typeof color === 'number') parsedColor = color.toString()
  if (typeof parsedColor !== 'string') return parsedColor
  const lowerCaseColor = parsedColor.toLowerCase()
  const named = getHexColorFromNamed(lowerCaseColor)
  const withoutHash = named ? named.replace(/#/gi, '') : lowerCaseColor
  return expandShortHandHexColor(withoutHash)
}

export const slug = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z.string().regex(/^[0-9]+(?:[-\w]+)?$/i)
)

export const numericString = z.preprocess((v) => (typeof v === 'number' ? String(v) : v), z.string().regex(/[0-9]+/))
export const optionalNumericString = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z
    .string()
    .regex(/[0-9]+/)
    .optional()
)
export const hexColorString = z.string().regex(/^[0-9a-fA-F]{6}$/i)

export const htmlColorString = z.preprocess(
  htmlColorTransform,
  hexColorString.or(z.literal('transparent')).or(z.literal('00000000'))
)
export const htmlColorStringWithoutTransparent = z.preprocess(htmlColorTransform, hexColorString)

export const locale = z.enum(['de', 'en'])

export const localeSchema = z.object({
  locale,
})

export const localeWithSlugSchema = z.object({
  locale,
  slug,
})

export const emptyObject = z.object({})

export const receiverTypePathParam = z.enum(['projects', 'fundraising-events'])
export function getRequiredCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(1)
      .max(capAt)
      .transform((val) => unescape(sanitize(val)))
  )
}
export function getOptionalCappedString(capAt: number) {
  return z.preprocess(
    (v) => (typeof v === 'number' ? String(v) : v),
    z
      .string()
      .min(0)
      .max(capAt)
      .optional()
      .transform((val) => val && unescape(sanitize(val)))
  )
}

export const requiredCappedString255 = getRequiredCappedString(255)
export const cappedString255 = getOptionalCappedString(255)
export const cappedString64 = getOptionalCappedString(64)
export const requiredCappedString64 = getRequiredCappedString(64)
export const text = z.preprocess(
  (v) => (typeof v === 'number' ? String(v) : v),
  z.string().transform((val) => (typeof val === 'string' && val ? unescape(sanitize(val)) : val))
)
