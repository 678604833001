import ActionFormContext from '../ActionFormContext'
import { type FieldValues, useFormContext } from 'react-hook-form'
import { useContext } from 'react'
import { useFormStatus } from 'react-dom'
import type { UseActionFormReturn } from '../useActionForm'

function useActionFormContext<T extends FieldValues, TOperationSuccessResult>() {
  const { busy, setBusy, submit, defaultValues } = useContext(ActionFormContext) as Pick<
    UseActionFormReturn<T, TOperationSuccessResult>,
    'busy' | 'setBusy' | 'submit' | 'defaultValues'
  >
  const status = useFormStatus()
  const pending = status?.pending // status is sometime null
  const context = useFormContext<T>()
  return { ...context, busy: busy || pending, setBusy, submit, defaultValues }
}

export default useActionFormContext
