/* eslint-disable import/prefer-default-export */
import type { BaseSyntheticEvent } from 'react'

export default function createSyntheticEvent<TElement extends Element, TEvent extends Event>(
  event: TEvent
): BaseSyntheticEvent<TEvent, TElement, TElement> {
  let isDefaultPrevented = false
  let isPropagationStopped = false
  const preventDefault = () => {
    isDefaultPrevented = true
    event.preventDefault()
  }
  const stopPropagation = () => {
    isPropagationStopped = true
    event.stopPropagation()
  }
  return {
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & TElement,
    target: event.target as EventTarget & TElement,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {},
    timeStamp: event.timeStamp,
    type: event.type,
  }
}
