import { Locale } from '@/i18n/types'
import { Maybe, MultilingualText } from '@betterplace/api-graphql-types'
function getLocalizedValue(locale: Locale, field?: Maybe<MultilingualText>): string {
  if (!field) {
    return ''
  }
  const name = field[locale]
  if (typeof name === 'string') {
    return name
  }
  for (const key_ in field) {
    const key = key_ as keyof MultilingualText
    if (key === '__typename' || key === locale) continue
    const name = field[key]
    if (typeof name === 'string') {
      return name
    }
  }
  return ''
}

export default getLocalizedValue
