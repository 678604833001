/* eslint-disable import/no-unused-modules */
import { CommonError } from '.'

export interface IAppError {
  __typename: 'AppError'
  errors: Array<CommonError>
}

export default class AppError extends Error implements IAppError {
  readonly __typename: 'AppError' = 'AppError' as const
  readonly digest?: string
  readonly errors: Array<CommonError>

  constructor(errors: Array<CommonError>) {
    super(errors[0]?.type || 'AppError')
    this.errors = errors
  }

  toJSON(): IAppError {
    return {
      __typename: this.__typename,
      errors: this.errors,
    }
  }
}
