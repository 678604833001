function getPublicUrl() {
  // Currently only the new environments support route shadowing
  const supportsRouteShadowing = process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT.toLowerCase().split('-').includes('ops')
  if (supportsRouteShadowing) {
    return process.env.NEXT_PUBLIC_BETTERPLACE_URL
  }
  return process.env.NEXT_PUBLIC_BETTERPLACE_URL.replace('www', 'secure')
}

export default getPublicUrl
