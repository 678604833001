'use client'

import ActionFormContext from '../ActionFormContext'
import { type FieldValues, FormProvider } from 'react-hook-form'
import type { ActionFormProviderProps } from './types'

function ActionFormProvider<TFields extends FieldValues, TOperationSuccessResult>({
  children,
  busy,
  setBusy,
  submit,
  defaultValues,
  ...props
}: ActionFormProviderProps<TFields, TOperationSuccessResult>) {
  return (
    <ActionFormContext.Provider value={{ busy, setBusy, submit, defaultValues }}>
      <FormProvider {...props}>{children}</FormProvider>
    </ActionFormContext.Provider>
  )
}

export default ActionFormProvider
