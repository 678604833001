// eslint-disable-next-line @typescript-eslint/no-empty-function, import/exports-last
function delay(time: number, cb: () => void = () => {}) {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      try {
        cb()
        resolve(undefined)
      } catch (e) {
        reject(e)
      }
    }, time)
  })
}

export default delay
