import { NextIntlKeys, Translator } from '../types'
import { TranslationValues } from 'next-intl'

function getTranslatorWithFallback<T extends Translator>(t: T): T {
  return ((key: string, options?: Record<string, unknown> & { defaultValue?: string }) => {
    const message = t(key as NextIntlKeys, options as TranslationValues) ?? key
    if (message === key) {
      return options?.defaultValue ?? ''
    }
    return message
  }) as T
}

export default getTranslatorWithFallback
