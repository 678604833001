import { CamelCaseKey, SnakeCaseKey, toCamelCase, toSnakeCase } from '@betterplace/utils'
import { Pretty } from '@/types'

/* eslint-disable import/prefer-default-export */

type CamelCaseDictionaryKeys<Input extends Record<string, unknown>> = {
  [TKey in keyof Input as CamelCaseKey<TKey>]: Input[TKey]
}

type SnakeCaseDictionaryKeys<Input extends Record<string, unknown>> = {
  [TKey in keyof Input as SnakeCaseKey<TKey>]: Input[TKey]
}
export function camelCaseRecordKeys<Input extends Record<string, unknown>>(input: Input) {
  const result: Record<string, unknown> = {}
  for (const key in input) {
    if (key.startsWith('_')) continue
    result[toCamelCase(key)] = input[key]
  }
  return result as Pretty<CamelCaseDictionaryKeys<Input>>
}

export function snakeCaseRecordKeys<Input extends Record<string, unknown>>(input: Input) {
  const result: Record<string, unknown> = {}
  for (const key in input) {
    if (key.startsWith('_')) continue
    result[toSnakeCase(key)] = input[key]
  }
  return result as Pretty<SnakeCaseDictionaryKeys<Input>>
}
