import { type FieldPath, type FieldPathValues, type FieldValues, useFormContext, useWatch } from 'react-hook-form'

function useActionFormValues<T extends FieldValues, TPaths extends Array<FieldPath<T>>>(
  names: [...TPaths]
): [...FieldPathValues<T, [...TPaths]>] {
  const { getValues } = useFormContext()
  useWatch({ name: names })
  return getValues(names)
}

export default useActionFormValues
