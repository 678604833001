import delay from '../delay'
import type { UnpackMaybePromise } from '@/types'
type WithRetryOptions = {
  onError?: (attempt: number) => void
  onSuccess?: () => void
  onFailure?: () => void
  noOfRetries?: number
  baseDelayMs?: number
}
/* eslint-disable @typescript-eslint/no-explicit-any */
function withRetry<TCallback extends (...args: any[]) => any>(
  callback: TCallback,
  { onError, onSuccess, onFailure, noOfRetries = 3, baseDelayMs = 1000 }: WithRetryOptions = {}
) {
  return async function retry(...args: Parameters<TCallback>): Promise<UnpackMaybePromise<ReturnType<TCallback>>> {
    for (let i = 0; i < noOfRetries; i++) {
      if (i) await delay(Math.round(baseDelayMs * i ** 2))
      try {
        // eslint-disable-next-line no-var, @typescript-eslint/no-unsafe-assignment
        var result = await callback(...args)
        break
      } catch (e) {
        if (i === noOfRetries - 1) {
          onFailure?.()
          return Promise.reject(e)
        }
        onError?.(i)
      }
    }
    onSuccess?.()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result
  }
}

export default withRetry
