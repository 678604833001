import { ZodIssueCode } from 'zod'
import { getTranslatorWithFallback } from '..'
import type { GetErrorMap } from '@/helpers/operations'
import type { NextIntlKeys } from '../types'

function getIssueValidationTranslationKey(validation: string): NextIntlKeys<'nextjs'> {
  switch (validation) {
    case 'email':
    case 'url':
    case 'uuid':
      return `errors.messages.invalid_${validation}`
    case 'regex':
      return `errors.messages.invalid_value`
    default:
      return 'errors.messages.invalid_value'
  }
}

const getGlobalErrorMap: GetErrorMap = (t_) => {
  const t = getTranslatorWithFallback(t_)
  return (issue, ctx) => {
    try {
      switch (issue.code) {
        case ZodIssueCode.invalid_enum_value:
          return { message: t('errors.messages.invalid_enum_value', { options: issue.options.join(', ') }) }
        case ZodIssueCode.invalid_string:
          {
            if (typeof issue.validation === 'string') {
              if (issue.path.includes('video_start_time')) {
                if (issue.validation === 'regex') {
                  return {
                    message: t('donate.errors.youtube_timestamp_malformed'),
                  }
                }
              }
              return {
                message: t(getIssueValidationTranslationKey(issue.validation)),
              }
            }
          }
          break
        case ZodIssueCode.not_multiple_of:
          return { message: t(`errors.messages.not_multiple_of`, { multipleOf: issue.multipleOf.toString() }) }
        case ZodIssueCode.too_small:
        case ZodIssueCode.too_big: {
          const constraint = (issue.code === ZodIssueCode.too_small ? issue.minimum : issue.maximum).toString()
          if (issue.type === 'number') {
            const inclusiveSuffix = issue.inclusive ? '_inclusive' : ''
            return {
              message: t(`errors.messages.${issue.type}.${issue.code}${inclusiveSuffix}`, {
                constraint,
              }),
            }
          }
          if (issue.type === 'string') {
            return { message: t(`errors.messages.${issue.type}.${issue.code}`, { constraint }) }
          }
          break
        }
        case ZodIssueCode.invalid_literal: {
          return {
            message: t('errors.messages.invalid_literal', {
              literal: JSON.stringify(issue.expected),
              received: JSON.stringify(issue.received),
            }),
          }
        }
        case ZodIssueCode.custom:
          return { message: t('errors.messages.default') }
        default:
          return {
            message: t(`errors.messages.${issue.code}` as NextIntlKeys<'nextjs'>, {
              defaultValue: ctx.defaultError,
            }),
          }
      }
      return {
        message: ctx.defaultError,
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
      console.error('Error localising validation', (e as any).message)
      return {
        message: ctx.defaultError,
      }
    }
  }
}

export default getGlobalErrorMap
