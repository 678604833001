import getGlobalErrorMap from './getGlobalErrorMap'
import useLocale from '../useLocale'
import { useMemo } from 'react'
import { useTranslations } from 'next-intl'
import { z } from 'zod'

function useI18nZodErrorMap() {
  const t = useTranslations('nextjs')
  const locale = useLocale()
  const map = useMemo(() => getGlobalErrorMap(t, locale), [t, locale])
  z.setErrorMap(map)
}

export default useI18nZodErrorMap
