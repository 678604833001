/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'
import { createContext } from 'react'
import type { ImperativeSubmit } from './useActionForm'

const initialState = {
  busy: false,
  setBusy: (_: boolean) => {},
  submit: (() => {
    throw new Error('Sumbission failed')
  }) as ImperativeSubmit<any, any>,
  defaultValues: undefined as Record<string, string> | undefined,
}

const ActionFormContext = createContext(initialState)
export default ActionFormContext
