/* eslint-disable import/exports-last */
import { fromFormDataFlattened, parseFieldValue } from '@/form/utils'
import { get } from 'lodash'
import { keysOf } from '@betterplace/utils'
import { useEffect, useId, useRef } from 'react'
import { z } from 'zod'
import type { Path, useForm } from 'react-hook-form'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldValueSetter<TSchema extends z.Schema> = ReturnType<typeof useForm<TSchema, any, undefined>>['setValue']
export type UseHydrateFormWithUserDataReturn = {
  // id that needs to be passed to the form element
  id: string
}

function useHydrateFormWithUserData<TSchema extends z.Schema>(
  setValue: FieldValueSetter<TSchema>,
  defaultValues: z.infer<TSchema>,
  passThroughId?: string
): UseHydrateFormWithUserDataReturn {
  const generatedId = useId()
  const id = passThroughId ?? generatedId
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hydrationFormData = useRef<Record<Path<z.infer<TSchema>>, any> | null>(null)
  if (!hydrationFormData.current && typeof document !== 'undefined') {
    const form = document.getElementById(id) as HTMLFormElement | null
    if (form) {
      const data = new FormData(form)
      const values = fromFormDataFlattened<z.infer<TSchema>>(data) ?? null
      hydrationFormData.current = values
    }
  }

  useEffect(() => {
    const values = hydrationFormData.current
    if (!values) return
    for (const key of keysOf(values)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let value = values[key]
      const defaultValue = get(defaultValues, key)
      // handle checkboxes
      if (typeof defaultValue === 'boolean') {
        value = !!value && (value === 'on' || value === 'true') ? true : false
      }
      // handle decimals (esp. currency inputs)
      if (typeof defaultValue === 'number') {
        value = typeof value === 'string' ? value.replace(/[.,]/g, '.') : value
      }
      if (value === defaultValue) continue
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value = parseFieldValue(value) // parse it again, they might be now formatted differently
      if (value === defaultValue) continue
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      setValue(key as Path<z.infer<TSchema>>, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { id }
}

export default useHydrateFormWithUserData
