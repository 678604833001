const RailsRoutes = {
  signIn: '/[locale]/users/sign_in?return_to',
  signOut: '/[locale]/users/sign_out',
  userProfile: '/[locale]/users/[id]',
  userDonationOverview: '/[locale]/t/user/donation_overview',
  startNewProject: '/[locale]/projects/new?tracking_via',
  startNewFunE: '/[locale]/fundraising-events/registration/preselection?tracking_via',
  imprint: '/c/contact',
  fundraisingEventEdit: '/[locale]/fundraising-events/[slug]/manage/details/edit',
  aboutUs: '/c/ueber-uns',
  contactUs: '/c/contakt',
  discovery: '/[locale]/discover?q',
  collect: '/c/spenden-sammeln',
} as const

export default RailsRoutes
